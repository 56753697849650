.small {
  line-height: 0;
  svg {
    width: 16px;
    height: 16px;
  }
}
.default {
  line-height: 0;
  svg {
    width: 24px;
    height: 24px;
  }
}

.large {
  line-height: 0;
  svg {
    width: 32px;
    height: 32px;
  }
}
