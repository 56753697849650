.backArrow {
  padding: 7px 4px 6px;
  svg {
    fill: #4f4a5599;

    path {
      fill: #4f4a5599;
      fill-opacity: 1;
    }
  }
}
