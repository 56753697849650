/* global css start hear */
.Toastify__toast-theme--colored.Toastify__toast--success {
  background: linear-gradient(0deg, rgba(54, 195, 149, 0.08), rgba(54, 195, 149, 0.08)), #ffffff !important;
  color: #4f4a55 !important;
  font-weight: 400;
  font-size: 13px;
  line-height: 16px;
}

.Toastify__toast-theme--colored.Toastify__toast--success svg {
  fill: #36c395;
}

.Toastify__toast-theme--colored.Toastify__toast--error {
  background: linear-gradient(0deg, rgba(255, 0, 127, 0.08), rgba(255, 0, 127, 0.08)), #ffffff !important;
  color: #4f4a55 !important;
  font-weight: 400;
  font-size: 13px;
  line-height: 16px;
}

.Toastify__toast-theme--colored.Toastify__toast--error svg {
  fill: #ff007f;
}

.download-small-icon svg {
  width: 12px;
  height: 13.66px;
}

.download-small-icon svg path {
  fill: #4f4a5599;
}

.primary-svg svg {
  width: 20px;
  height: 20px;
}
.primary-svg svg path {
  width: 20px;
  fill: #36c395 !important;
}
.warning-success svg path {
  fill: #36c395;
}
.warning-info svg path {
  fill: #6f00e7;
}
.warning-warning svg path {
  fill: #fe9800;
}
.warning-error svg path {
  fill: #ff007f;
}
.arrow-width svg {
  width: 15px;
}
.left-arrow svg {
  transform: rotate(90deg);
}
.rotate180 svg {
  transform: rotate(180deg);
}
.right-arrow svg {
  transform: rotate(-90deg);
}
.down-arrow svg {
  transform: rotate(180deg);
}
.plus-white svg path {
  stroke: white !important;
}
.recharts-legend-item-text {
  color: #4f4a5599 !important;
}
