.profile_save_btn {
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
}

.profile_page {
  display: flex;
  justify-content: space-around;
  align-items: center;
  column-gap: 20px;
  .inputs {
    display: flex;
    flex-direction: column;
    row-gap: 10px;
    width: 50%;
  }
}
.profile_pic_section {
  display: flex;
  align-items: center;
  flex-direction: column;
  row-gap: 10px;
}
.upload_profile_btn {
  display: flex;
  align-items: center;
  column-gap: 5px;
  justify-content: space-between;
  border: 0.5px solid #4f4a553b;
  padding: 8px;
  border-radius: 6px;
  cursor: pointer;
}
